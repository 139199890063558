<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-row>
                <b-col md="4">
                    <base-input type="text" :label="$t('general.search_area')" :placeholder="$t('general.search_area')" v-model="filter.title" @input="searchTimeOut">
                    </base-input>
                </b-col>
                <b-col md="4">
                    <base-input :label="$t('general.lang')" name="lang" :nameAs="$t('general.lang')" :rules="'required'">
                        <el-select v-model="filter.lang" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required @change="getData">
                            <el-option v-for="(item, key) in setting.lang" :key="key" :label="item.toUpperCase()" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="4" class="text-right">
                    <base-button type="success" @click="addNew" class="my--5">
                        <i class="fas fa-plus"></i> {{$t('general.addnew')}}
                    </base-button>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="pageList" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')">
                <el-table-column :label="$t('general.lang')" prop="lang" width="100">
                    <template v-slot="{row}">
                        <span :class="'iti-flag '+row.lang"></span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('page.title')" prop="title" min-width="250">
                </el-table-column>

                <el-table-column :label="$t('page.publish_status')" prop="status" min-width="160">
                    <template v-slot="{row}">
                        <badge class="badge-dot mr-4" type="">
                            <i :class="`bg-${status[row.status]}`"></i>
                            <span class="status" :class="`text-${status[row.status]}`">{{row.status?$t('general.publish'):$t('general.not_publish')}}</span>
                        </badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('page.menu_status')" prop="status" min-width="160">
                    <template v-slot="{row}">
                        <badge class="badge-dot mr-4" type="">
                            <i :class="`bg-${status[row.menu]}`"></i>
                            <span class="status" :class="`text-${status[row.menu]}`">{{row.menu?$t('general.show'):$t('general.hide')}}</span>
                        </badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.created_date')" prop="createdAt" min-width="170" sortable>
                    <template v-slot="{row}">
                        {{row.createdAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.update_date')" prop="updatedAt" min-width="170" sortable>
                    <template v-slot="{row}">
                        {{row.updatedAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="180">
                    <template slot-scope="scope">
                        <base-button type="primary" size="sm" @click="handleEdit(scope.row)">
                            {{$t('general.edit')}}</base-button>
                        <base-button type="danger" size="sm" @click="handleDelete(scope.row._id)">{{$t('general.delete')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>
        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modal.status=false" size="lg">
            <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                <h6 slot="header" class="modal-title">{{modal.data._id ? $t('page.edit') : $t('page.add')}}</h6>
                <hr class="my-2">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="modal.loading">
                    <b-row>
                        <b-col md="2">
                            <base-input :label="$t('general.lang')" name="lang" :nameAs="$t('general.lang')" :rules="'required'">
                                <el-select v-model="modal.data.lang" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required>
                                    <el-option v-for="(item, key) in setting.lang" :key="key" :label="item.toUpperCase()" :value="item"></el-option>
                                </el-select>
                            </base-input>
                        </b-col>
                        <b-col md="7">
                            <base-input type="text" name="title" :nameAs="$t('page.title')" :label="$t('page.title')" :placeholder="$t('page.title')" v-model="modal.data.title" required @input="generateSeo('title')">
                            </base-input>
                        </b-col>
                        <b-col md="3">
                            <base-input type="text" name="seo" :nameAs="$t('general.seo')" :label="$t('general.seo')+'*'" :placeholder="$t('general.seo')" @input="generateSeo('seo')" v-model="modal.data.seo" required>
                            </base-input>
                        </b-col>
                        <b-col md="12">
                            <base-input type="text" name="desc" :nameAs="$t('page.desc')" :label="$t('page.desc')" :placeholder="$t('page.desc')" v-model="modal.data.desc">
                            </base-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="4">
                            <label class="form-control-label">{{$t('page.publish_status')}}</label>
                            <b-form-checkbox v-model="modal.data.status" class="switchCheck dblock" name="check-button" switch>
                                {{modal.data.status ? $t('general.publish') : $t('general.not_publish')}}
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="4">
                            <label class="form-control-label">{{$t('page.show_menu')}}</label>
                            <b-form-checkbox v-model="modal.data.menu" class="switchCheck dblock" name="check-button" switch>
                                {{modal.data.menu ? $t('general.show') : $t('general.hide')}}
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="4">
                            <base-input :label="$t('page.column')" name="column" :nameAs="$t('page.column')" :rules="'required'" v-if="modal.data.menu===true">
                                <el-select v-model="modal.data.column" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required @change="getData">
                                    <el-option v-for="item in 3" :key="item" :label="item+'.'+$t('page.section')" :value="item"></el-option>
                                </el-select>
                            </base-input>
                        </b-col>
                    </b-row>
                    <quill-editor v-model="modal.data.content" :options="editorOption" />
                    <hr class="my-2">
                    <b-alert show variant="warning" v-if="invalid || modal.data.content.trim()==''">
                        <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                    </b-alert>
                    <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid || modal.data.content.trim()==''">{{$t('save')}}</base-button>
                    <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{$t('close')}}</base-button>
                </b-form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import getSlug from 'speakingurl';
import { mapGetters } from 'vuex';
import { PAGE_REQUEST, PAGE_UPDATE, PAGE_ADD, PAGE_DELETE } from '@/store/modules/define';
import { handleError } from '@/utils/func';

let self;
let emptyData = {
    id: false,
    title: '',
    seo: '',
    content: '',
    desc: '',
    lang: 'tr',
    menu: false,
    column: 1,
    status: true
};

export default {
    data() {
        return {
            timer: null,
            loading: false,
            modal: {
                status: false,
                loading: false,
                data: _.cloneDeep(emptyData)
            },
            status: {
                true: 'success',
                false: 'danger'
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {
                title: '',
                lang: 'tr'
            },
            editorOption: {
                modules: {
                    clipboard: {
                        matchVisual: true
                    },
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                        ['clean'],
                        ['link', 'image']
                    ]
                },
                placeholder: ''
            }
        };
    },
    computed: {
        ...mapGetters({
            pageList: 'getPageList',
            pages: 'getPagePages',
            total: 'getPageTotal',
            setting: 'generalSetting'
        })
    },
    methods: {
        changePage(val) {
            self.pagination.page = val;
            self.getData();
        },
        generateSeo(key) {
            let lang = self.$i18n.locale;
            self.modal.data.seo = getSlug(self.modal.data[key], { lang });
        },
        addNew() {
            self.modal = {
                status: true,
                loading: false,
                data: _.cloneDeep(emptyData)
            };
        },
        handleEdit(row) {
            self.modal = {
                status: true,
                loading: false,
                data: _.cloneDeep(row)
            };
        },
        handleDelete(id) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(PAGE_DELETE, id)
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(PAGE_REQUEST, { pagination: self.pagination, filter: self.filter, fields: '' })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        onSubmit() {
            if (self.modal.data._id) {
                self.onUpdate();
            } else {
                self.onAdd();
            }
        },
        onAdd() {
            let data = _.cloneDeep(self.modal.data);
            self.modal.loading = true;
            self.$store
                .dispatch(PAGE_ADD, data)
                .then(() => {
                    self.modal.loading = false;
                    self.modal.status = false;
                    self.getData();
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        onUpdate() {
            let data = _.cloneDeep(self.modal.data);
            let id = data._id;
            self.modal.loading = true;
            self.$store
                .dispatch(PAGE_UPDATE, { id, page: data })
                .then(() => {
                    self.modal.loading = false;
                    self.modal.status = false;
                    self.getData();
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData();
            }, 800);
        }
    },
    filters: {},
    created() {
        self = this;
        self.getData();
    }
};
</script>
